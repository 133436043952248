@import url("https://fonts.googleapis.com/css2?family=Gudea:ital,wght@0,400;0,700;1,400&family=KoHo:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Mulish:ital@0;1&family=Odibee+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
.flick-scroller {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.flick-scroller .video-shot {
  position: absolute;
  height: 100%;
  width: 100%;
}
.flick-scroller .video-shot .shot-player {
  max-width: 700px;
  margin: auto;
  height: calc(100vh - 65px - 25px);
  background-color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}
@media (max-width: 768px) {
  .flick-scroller .video-shot .shot-player {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
  }
}
.flick-scroller .video-shot .shot-player .video-info {
  bottom: 0px;
}
@media (max-width: 768px) {
  .flick-scroller .video-shot .shot-player .video-info {
    bottom: 80px;
  }
}
.flick-scroller .video-shot .shot-player .shot-video {
  height: 90%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media (max-width: 768px) {
  .flick-scroller .video-shot .shot-player .shot-video {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}
.flick-scroller .video-shot .shot-player .action-btn-container {
  background: rgba(67, 67, 67, 0.4705882353);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.flick-scroller .video-shot .shot-player .action-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
}
.flick-scroller .video-shot.smooth {
  transition: 0.2s;
}
.flick-scroller .video-shot .ad-slot {
  background: black;
  color: white;
  flex-direction: column;
}
.flick-scroller .video-shot .ad-slot a {
  user-select: none;
  -webkit-user-drag: none;
  width: 75%;
  height: 70%;
}
.flick-scroller .video-shot .ad-slot a iframe {
  width: 100%;
  height: 100%;
}
.flick-scroller.smooth .video-shot {
  transition: 0.2s;
}

.section-navigation {
  width: 300px;
}

.mobile-navbar {
  bottom: 5px;
  height: 65px;
  border-radius: 15px;
  padding: 10px 20px;
  background-color: white;
  box-shadow: #dedcdb -1px 0px 10px 1px;
}
.mobile-navbar a {
  z-index: 5;
  color: #aabdcb;
}
.mobile-navbar a.active, .mobile-navbar div.active {
  color: #de0504;
}
.mobile-navbar a::after {
  content: "";
  bottom: 10px;
  left: 35px;
  width: 0px;
  height: 5px;
  background: #004071;
  position: absolute;
  left: -15px;
  bottom: -10px;
  transition: 0.25s;
  transform-origin: 0%;
}
.mobile-navbar a:hover {
  color: #de0504;
}
.mobile-navbar a.active::after {
  width: calc(100% + 30px);
}

.nav-tabs {
  --bs-nav-tabs-link-active-bg: #ff9e00;
  --bs-nav-tabs-link-active-color: #ffffff;
}
.nav-tabs .nav-link.active {
  border: none;
  transform: translate(0, 1px);
}

.game-theme .section-content {
  margin-top: 60px;
  width: 100%;
}
.game-theme .section-content .mp-game {
  border: none;
  height: calc(var(--vh) - 55px);
}
.game-theme .section-content.no-profile {
  margin-left: unset;
  width: 100%;
}
.game-theme .section-content.no-header {
  margin-top: unset;
}
.game-theme .section-content.no-header .mp-game {
  height: calc(var(--vh) - 55px);
}
@media (max-width: 768px) {
  .game-theme .section-content {
    margin-left: unset;
    width: 100%;
    margin-top: unset;
  }
}

.section-content {
  position: relative;
}
@media (max-width: 768px) {
  .section-content {
    margin-left: 0;
    min-height: calc(100vh - 10px);
  }
}

.flick-drawer {
  position: absolute;
  left: 100%;
  width: 100%;
}
.flick-drawer.smooth {
  transition: 0.2s;
}

.flick-drawer-left {
  position: absolute;
  right: 100%;
  width: 100%;
  padding-bottom: 200px;
  overflow-y: auto;
}
.flick-drawer-left.smooth {
  transition: 0.2s;
}

.moplay-primary-bg {
  background-color: #0a4ca0;
}

.moplay-primary-color {
  color: #0a4ca0;
}

.moplay-secondary-bg {
  background-color: rgba(7, 7, 7, 0.5294117647);
}

.moplay-confident-bg {
  background-image: linear-gradient(to bottom, #ffffff 0%, #002643 100%);
}

.moplay-light-blood-bg {
  background-image: linear-gradient(180deg, #ffffff 75%, #ffc6c6 100%);
}

.moplay-corn-bg {
  background-color: #EFF1F2;
}

.moplay-lemon-bg {
  background-color: #ff9e00;
}

.moplay-yellow-clr {
  color: #ff9e00;
}

.moplay-lemon-clr {
  color: white;
}

.mp-text-main-red {
  color: #DA0707;
}

.mp-text-clr {
  color: #244055;
}

.border-bottom {
  border-bottom: 1px solid #BBC9D3 !important;
}

.moplay-red-bg {
  background-color: #0758da;
}

.mp-light-gray-bg {
  background-color: rgba(187, 201, 211, 0.5019607843);
}

.mp-popup-border-color {
  border: 4px solid rgba(189, 222, 246, 0.8) !important;
}

.form-control {
  border: 1px solid #CFD2D8 !important;
  box-shadow: 0px 2px 2px 0px #9FABBC inset !important;
}

.bg-light {
  background-color: #eef1f2 !important;
}

.bg-gradient-blue {
  background-color: #0C2575;
}

.bg-gradient-white {
  background: linear-gradient(180deg, #C6CAD6, #E5EBFF, #BBBDC3);
}

.mp-social-tab-container {
  border-radius: 0px;
  border-top-right-radius: 10px;
  scrollbar-width: none;
  padding: 20px;
}

.mp-social-container {
  height: 500px;
}

.mp-home-page-active {
  border-bottom: 3px solid red !important;
  background: transparent !important;
  color: #000000 !important;
}

.h-50vh {
  height: 50px;
}

.f-10 {
  font-size: 10px;
}

.f-14 {
  font-size: 14px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-25 {
  font-size: 25px;
}

.f-45 {
  font-size: 45px !important;
}

.wix-text {
  font-family: "Wix Madefor Display", sans-serif;
}

.game-theme.wix-text {
  font-family: "Itim", cursive;
}

.mp-follow-btn {
  width: 100px;
}

.btn {
  padding: 10px 45px;
}
.btn:hover {
  background-color: #ff9e00;
  border-color: #ff9e00;
}

.btn-primary {
  position: relative;
  background-color: #ff9e00;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
}
.btn-primary.active {
  background-color: #ff9e00;
}

.btn-sm {
  padding: 8px 25px;
}

.btn-secondary {
  border: none;
}

.btn-custom-olive {
  position: relative;
  background-color: #aea21f;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border: none;
}
.btn-custom-olive.active {
  background-color: #aea21f;
}
.btn-custom-olive:hover {
  background-color: #746b07;
  border-color: #746b07;
  color: #ffffff;
}

.btn-outline-primary {
  border-color: #ff9e00;
  color: #ff9e00;
  box-shadow: 0 0 5px #eee;
}

.chat-screen {
  width: 500px;
  max-width: 90%;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10;
}
@media (max-width: 768px) {
  .chat-screen {
    bottom: 70px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.friend-info {
  display: flex;
  align-items: center;
}

.friend-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.friend-details {
  display: flex;
  flex-direction: column;
}

.friend-name {
  font-weight: bold;
  font-size: 16px;
}

.friend-address {
  font-size: 14px;
  color: #666666;
}

.close-btn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  color: #666666;
}

.close-btn:hover {
  color: #333333;
}

.messages-container {
  height: calc(var(--vh) - 300px);
  overflow-y: auto;
  padding: 10px;
}

.message {
  max-width: 55%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
}

.sent {
  background-color: #171717;
  color: #ffffff;
  align-self: flex-end !important;
  margin-left: auto;
}
.sent .indicator {
  text-shadow: 6px 0px;
}
.sent .indicator.seen {
  color: #00eaff;
}
.sent .timing {
  color: #0dcaf0;
}

.received {
  background-color: #3d9fc4;
  color: #ffffff;
}
.received .timing {
  color: #ffec06;
}

.message-content {
  word-wrap: break-word;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.message-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  outline: none;
}

.send-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}

.send-button:hover {
  background-color: #0056b3;
}

.mp-publisher-profile-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.mp-publisher-banner-image {
  width: 100%;
  border-radius: 10px;
  height: 150px;
  object-fit: cover;
}

.mp-user-profile-icon {
  width: 35px;
  height: 35px;
}

.mp-shop-image {
  max-width: 100%;
  object-fit: contain;
  height: 100px;
}

.mp-notification-profile-img {
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.mp-header-logo-image {
  object-fit: contain !important;
}

.mp-game-icon {
  height: 300px;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  object-fit: cover !important;
  position: relative;
}
@media (max-width: 768px) {
  .mp-game-icon {
    height: 200px;
  }
}

.game-card-title {
  bottom: 0;
  width: 100%;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}
.game-card-title .description {
  display: -webkit-box; /* Required for line clamping */
  -webkit-box-orient: vertical; /* Set orientation to vertical */
  overflow: hidden; /* Hide overflowing text */
  -webkit-line-clamp: 3; /* Limit text to 3 lines */
  text-overflow: ellipsis; /* Add ellipsis for overflow */
}

.mp-recent-games-image {
  height: 130px !important;
}

.mp-game-result-profile-icon {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: inset;
}
@media (max-width: 768px) {
  .mp-game-result-profile-icon {
    height: 100px;
    width: 100px;
  }
}

.transition-500ms {
  transition: 0.5s;
}

.idle {
  left: 0;
}

.to-left {
  transform: translate(-110%, 0);
  height: 0;
}

.to-right {
  transform: translate(110%, 0);
  height: 0;
}

.to-bottom {
  transform: translate(0, 120%);
}

.anim-left-to-right {
  animation: LeftToRight 0.75s;
}

.heartbeat {
  animation: HeartBeatAnim 0.25s;
  transition: 0.5s;
}

@keyframes LeftToRight {
  0% {
    transform: translate(-110%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
.anim-right-to-left {
  animation: RightToLeft 0.75s;
}

@keyframes RightToLeft {
  0% {
    transform: translate(110%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes HeartBeatAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.game-theme .headerbar {
  position: fixed !important;
  width: 100%;
  top: 0;
  z-index: 999;
}
@media (max-width: 768px) {
  .game-theme .headerbar {
    position: sticky !important;
  }
}

.headerbar {
  display: flex;
  align-items: center;
  height: 60px;
}
.headerbar .coins {
  position: absolute;
  right: 10px;
}
.headerbar.horizontal .nav-container {
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 30px;
}
.headerbar.horizontal .right-align {
  position: absolute;
  right: 10px;
}

.mr-alert {
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 10px 40px;
  top: 0px;
  transform: translate(0, -100%);
  transition: 0.3s;
}
.mr-alert.active {
  transform: translate(0, 10px);
}
.mr-alert .altert-content {
  background: linear-gradient(175deg, #28b7e9, #725bb6);
  border-radius: 50px;
  padding: 10px;
  text-align: center;
}

.counter-timer {
  background-color: #ff9e00;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-theme .profile {
  background-color: unset;
  position: fixed;
  width: 300px;
  left: 0;
  height: calc(100vh - 60px);
  left: -100%;
}
#profile_toggler_checkbox:checked + .profile {
  left: 0;
}

.profile {
  position: fixed;
  top: 0;
  left: -100%;
  background-color: rgb(46, 46, 46);
  padding-bottom: 100px;
  overflow-y: auto;
  z-index: 1111;
  transition: 0.25s;
}
@media (max-width: 768px) {
  .profile {
    height: 100vh;
  }
}
.profile .options {
  list-style-type: none;
  padding-left: 0;
  padding-top: 30px;
}
.profile .options a {
  text-decoration: none;
}
.profile .options a.active li {
  color: white;
  background-color: #ff9e00;
}
.profile .options li {
  background-color: white;
  color: black;
  padding: 10px 30px;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.profile .close-btn {
  position: absolute;
  padding: 15px;
  right: 0;
  cursor: pointer;
}
.profile.no-header {
  top: unset;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.back-btn-title a {
  text-decoration: none;
  color: unset;
}

.splash {
  height: 100vh;
  position: fixed;
  z-index: 999;
  width: 100%;
  background-color: #43f5ff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f3fbff, #9bc0e6);
}
.splash img {
  animation: logo-anim 2s;
}

@keyframes logo-anim {
  0% {
    transform: rotate(720deg) scale(3.5);
  }
  90% {
    transform: rotate(0deg) scale(1);
  }
}
.shadow-bottom {
  box-shadow: 0px 3px 3px 0px rgba(173, 173, 173, 0.52);
  z-index: 1;
  position: relative;
}

.daily-bonus .bonus-card {
  background-color: #0C2575;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
}
.daily-bonus .bonus-card .day-info {
  background-color: #D5890E;
  position: absolute;
  top: -10px;
  font-size: 14px;
  border-radius: 20px;
  padding: 5px 10px;
}
.daily-bonus .bonus-card .coins {
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  position: relative;
}
.daily-bonus .bonus-card .collection-container {
  bottom: -10px;
}
.daily-bonus .bonus-card .collection-container button {
  background: linear-gradient(180deg, #C6CAD6, #E5EBFF, #BBBDC3);
  color: #818181;
  font-weight: bold;
  font-size: 14px;
}
.daily-bonus .bonus-card.collected {
  opacity: 0.5;
}
.daily-bonus .bonus-card.collect {
  cursor: pointer;
}

.leaderboard .top-rankers {
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 50px;
}
.leaderboard .top-rankers .ranker {
  position: relative;
  width: 100px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
.leaderboard .top-rankers .ranker .dp {
  position: absolute;
  left: 20px;
  top: -20px;
}
.leaderboard .top-rankers .ranker .player-info {
  padding: 50px 10px 10px 10px;
  word-break: break-all;
  font-size: 14px;
}
.leaderboard .top-rankers .ranker .rank-number {
  position: absolute;
  bottom: -50px;
  width: 100%;
  text-align: center;
  font-size: 25px;
}
.leaderboard .top-rankers .rank-1 {
  height: 180px;
  background-color: #2174fa;
}
.leaderboard .top-rankers .rank-2 {
  height: 140px;
  background-color: #3a75d4;
}
.leaderboard .top-rankers .rank-3 {
  height: 120px;
  background-color: #2c589f;
}
@keyframes rank-1 {
  0% {
    height: 0px;
  }
  100% {
    height: 200px;
  }
}
@keyframes rank-2 {
  0% {
    height: 0px;
  }
  100% {
    height: 150x;
  }
}
@keyframes rank-3 {
  0% {
    height: 0px;
  }
  100% {
    height: 120px;
  }
}

.notifications {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #222222;
  z-index: 99;
  height: 100vh;
  width: 80%;
  max-width: 800px;
  overflow: auto;
  transition: 0.5s;
  padding-bottom: 100px;
}
.notifications.open {
  right: 0;
}
.notifications.close {
  right: -100%;
}

@media (max-width: 768px) {
  .footer {
    margin-left: unset;
  }
}

.no-profile .footer {
  margin-left: unset;
  width: 100%;
}

.horizontal-navbar {
  background-color: red;
  height: 100px;
}

.user-info {
  position: absolute;
  top: 65px;
  right: 5px;
  background-color: #eef1f2;
  z-index: 9;
  border-radius: 30px;
  overflow: hidden;
  animation: RightToLeft 0.4s;
  padding: 10px 30px;
}
.user-info .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.user-info .nav-container {
  display: flex;
  flex-direction: column;
}
.user-info .nav-container a {
  padding: 10px 60px;
}
.user-info .user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  padding-bottom: 10px;
}

html,
body {
  overscroll-behavior: none;
}

body #root {
  min-height: calc(var(--vh) - 65px);
}
body a {
  text-decoration: none;
  color: unset;
}

.moplay .m-popup {
  background-color: rgba(38, 37, 37, 0.2901960784);
}
.moplay .m-popup.full-cover {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.moplay .m-popup-body {
  min-width: 360px;
  max-width: 100% !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.moplay .m-popup-show {
  display: block;
}
.moplay .m-popup-hide {
  display: none;
}
.moplay .m-notification-popup.full-covered {
  position: fixed;
  left: auto;
  right: 10px;
  top: 55px;
  bottom: auto;
  display: flex;
  z-index: 99;
  align-items: center;
  justify-content: center;
}
.moplay .popup-heading {
  padding: 10px 20px;
  position: relative;
  background-color: #d6c360;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.moplay .popup-heading .heading-label {
  padding-top: 30px;
  font-weight: 800;
  text-align: center;
}
.moplay .popup-heading .close-btn {
  position: absolute;
  right: 0;
  top: -40px;
  left: -4px;
  padding: 10px;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
}
.moplay .popup-heading .close-btn img {
  border-radius: 50%;
  background-color: #d6c360;
}
.moplay .m-popup-content {
  margin-top: 20px;
  padding: 20px;
}
.moplay .m-actions {
  padding: 20px 20px;
  text-align: center;
}
.moplay .m-popup-header-bg {
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  height: 170px;
  position: relative;
  top: 115px;
  z-index: 6;
}
.moplay .mp-popup-header-position {
  position: relative;
  top: -115px;
}
.moplay .mp-close-icon {
  cursor: pointer;
  z-index: 12;
  border-radius: 20px;
  top: -68px !important;
  left: 38%;
}
.moplay img {
  max-width: 100%;
  max-height: 100%;
}
.moplay .title-reg {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  color: white;
}
.moplay .scroll-container {
  height: calc(var(--vh) - 65px);
  overflow: auto;
  overflow-x: hidden;
}
.moplay .moplay-header {
  height: 65px;
}
.moplay .navigation-arrow-btn {
  width: 50px;
  height: 50px;
  background-color: lightgray;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
.moplay .mp-game-video-progress-bar {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 80px;
  width: 100%;
  height: 3px;
  background-color: #ccc;
}
.moplay .mp-game-promo-video {
  height: 100%;
  background-color: #c30303;
}
.moplay .moplay-user-profile-image {
  width: 200px;
  height: 200px;
}
.moplay .mp-cursor-pointer {
  cursor: pointer;
}
.moplay .mp-cursor-pointer-not-allowed {
  cursor: not-allowed;
}
.moplay .mp-camera-icon {
  padding: 6px;
  bottom: 3px;
}
.moplay .mp-camera-icon-position {
  bottom: 20px;
  right: 15px;
}
.moplay .mp-user-info-spinner-position {
  bottom: 225px;
  left: 47%;
}
@media (max-width: 768px) {
  .moplay .mp-scroll-bar-width {
    scrollbar-width: none;
  }
}
.moplay .mp-friend-profile-img {
  width: 40px;
  height: 40px;
}
.moplay .moplay-game-icon-image {
  max-width: 100%;
  border-radius: 15px;
  height: 150px;
  object-fit: contain;
}
.moplay .moplay-play-game {
  height: var(--vh);
  display: flex;
  flex-direction: column;
}
.moplay .mp-mt-50 {
  margin-top: 50px;
}
.moplay .mp-game-list-promo-video {
  margin-top: 0px;
  width: 250px;
  height: 30%;
  border-radius: 20px;
}
.moplay .moplay-promo-video-play-button-position {
  left: 50%;
  transform: translateX(-50%);
}
.moplay .mp-app-icon {
  border-radius: 50%;
  object-fit: cover;
  width: 22px;
  height: 22px;
}
.moplay .pb-50 {
  padding-bottom: 50px;
}
.moplay .mp-scroll-width-none {
  scrollbar-width: none !important;
}
.moplay .pointer {
  cursor: pointer;
}
.moplay .games-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 1024px) {
  .moplay .games-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) {
  .moplay .games-list {
    grid-template-columns: 1fr 1fr;
  }
}

